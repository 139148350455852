import React, { useEffect } from "react";
import {
    TableCell,
  } from "@material-ui/core";
  import { Input } from 'antd';

const SearchCellString = ({ name, align, dkey, isString, updateFilteredContentCallback, isSearch}) => {
  const onChangeSearch = (val) => updateFilteredContentCallback(val, dkey, isString)
  
  // useEffect(() => {
  //   if(!updateFilteredContentCallback){
  //     return;
  //   }
  //   const filter = localStorage.getItem(`sinup_user_search_cell_${name}`)
  //   if(filter) onChangeSearch(filter);
  // }, [updateFilteredContentCallback])

  return (

    <>
      {isSearch ? 
    <TableCell
      align={align}
      className="searchCell"
      id={name}
    > 
     {/* <Input placeholder="" defaultValue={localStorage.getItem(`sinup_user_search_cell_${name}`) || ""} onChange={(e) => {
          //localStorage.setItem(`sinup_user_search_cell_${name}`, e.target.value);
          onChangeSearch(e.target.value)
        }}/> */}
      <Input placeholder=""   onChange={(e) => {
          //localStorage.setItem(`sinup_user_search_cell_${name}`, e.target.value);
          onChangeSearch(e.target.value)
        }}/>
    </TableCell >
    : <TableCell className="searchCell" id={name}></TableCell> }
    </>
  );
};
export default SearchCellString;
