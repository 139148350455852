// action customization
export const SET_ERROR = 'SET_ERROR'; 

export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// actions  user

export const SAVE_USER = 'SAVE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';

// instrument tokens

export const ADD_INSTRUMENT_TOKEN_BY_ID = 'ADD_INSTRUMENT_TOKEN_BY_ID';
export const ADD_INSTRUMENT_TOKEN_LIST = 'ADD_INSTRUMENT_TOKEN_LIST';
export const ADD_SECTOR_LIST = 'ADD_SECTOR_LIST';
export const ADD_INSTRUMENT_TOKEN_BY_SECTOR = 'ADD_INSTRUMENT_TOKEN_BY_SECTOR';
export const UPDATE_INSTRUMENT_TOKEN = 'UPDATE_INSTRUMENT_TOKEN';
export const ADD_FUTURES_NAMES = 'ADD_FUTURES_NAMES';
export const ADD_FUTURES_NAMES_BULK = 'ADD_FUTURES_NAMES_BULK';
export const ADD_FUTURES_PREV_BULK = 'ADD_FUTURES_PREV_BULK';
export const ADD_PRE_FUTURES_PREV_BULK = 'ADD_PRE_FUTURES_PREV_BULK';

// option chain 
export const ADD_OPTION_CHAIN_INSTRUMENTS = 'ADD_OPTION_CHAIN_INSTRUMENTS';
export const ADD_CHARTDATA = 'ADD_OPTION_CHAIN_INSTRUMENTS';
export const REMOVE_CHARTDATA = 'REMOVE_CHARTDATA';

export const ADD_CHARTDATA_STRANGLE = 'ADD_CHARTDATA_STRANGLE';
export const REMOVE_CHARTDATA_STRANGLE = 'REMOVE_CHARTDATA_STRANGLE';
export const ADD_REAL_CHARTDATA_STRANGLE = 'ADD_REAL_CHARTDATA_STRANGLE';


// futures data table

export const ADD_TOKEN_LIST = 'ADD_TOKEN_LIST';
export const ADD_TOKEN_NAMES = 'ADD_TOKEN_NAMES';
export const ADD_FUTURES_DATA = 'ADD_FUTURES_DATA';

export const GET_PROFILE = "GET_PROFILE";

// Broker Action

export const GET_BROKER_ACCOUNTS = 'GET_BROKER_ACCOUNTS';
export const SET_BROKER_LIST = 'SET_BROKER_LIST';
export const RELOAD_BROKER_ACCOUNTS = 'RELOAD_BROKER_ACCOUNTS';
export const EDIT_BROKER = 'EDIT_BROKER';
export const DELET_BROKER_SUCCESS = 'DELET_BROKER_SUCCESS';
export const DELET_BROKER_FAIL = 'DELET_BROKER_FAIL';
export const SHOW_BROKER_CONF_INFO = 'SHOW_BROKER_CONF_INFO';
export const LOGOUT_BROKER = 'LOGOUT_BROKER';
export const SET_BROKER_DETAILS = 'SET_BROKER_DETAILS';
export const SET_BROKER_DETAILS_ERROR = 'SET_BROKER_DETAILS_ERROR';
export const SET_BROKER_HOLDINGS = 'SET_BROKER_HOLDINGS';
export const SET_BROKER_ORDERS = 'SET_BROKER_ORDERS'; 
export const SET_BROKER_POSITIONS = 'SET_BROKER_POSITIONS'; 
export const SET_BROKER_TRADES = 'SET_BROKER_TRADES'; 


export const ORDER_WINDOW_OPEN = 'ORDER_WINDOW_OPEN'; 

export const SET_BROKER_RISKSETTINGS = 'SET_BROKER_RISKSETTINGS'; 
export const OPEN_ORDER_WINDOW = 'OPEN_ORDER_WINDOW'; 
export const OPEN_EDIT_ORDER_WINDOW = 'OPEN_EDIT_ORDER_WINDOW'; 
export const SWITCH_TRANSICATION_TYPE = 'SWITCH_TRANSICATION_TYPE'; 
export const CLOSE_ORDER_WINDOW = 'CLOSE_ORDER_WINDOW';
 
export const SET_POSITION_MODAL = 'SET_POSITION_MODAL'; 
export const SET_BASKET_MODAL = 'SET_BASKET_MODAL'; 
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET'; 
export const REMOVE_ITEM_TO_BASKET = 'REMOVE_ITEM_TO_BASKET'; 
export const POSITION_RELOAD_ACTION = 'POSITION_RELOAD_ACTION'; 
export const SET_BASKET_MODAL_EMPTY = 'SET_BASKET_MODAL_EMPTY'; 
export const SET_INSTRUMENTS_DETAILS = 'SET_INSTRUMENTS_DETAILS'; 
export const SET_LAST_TRADING_PRICE = 'SET_LAST_TRADING_PRICE'; 
export const SET_DEFAULT_BROKER = 'SET_DEFAULT_BROKER'; 
export const GET_SIGNLE_ORDER_MARGIN = 'GET_SIGNLE_ORDER_MARGIN'; 
export const SET_ORDER_WINDOW_LTP = 'SET_ORDER_WINDOW_LTP'; 
export const OPEN_BROKER_NOT_LOGIN_WINDOW = 'OPEN_BROKER_NOT_LOGIN_WINDOW'; 
export const SESSION_EXPIRED = 'SESSION_EXPIRED'; 
export const FETCH_PACKAGES = 'FETCH_PACKAGES'; 
export const SET_PACKAGES_LIST = 'SET_PACKAGES_LIST'; 
export const SET_APPLY_PROMOCODE = 'SET_APPLY_PROMOCODE'; 
export const SET_SUSCRIPTION_ORDER_ID = 'SET_SUSCRIPTION_ORDER_ID'; 
export const SET_SUSCRIPTION_STATUS = 'SET_SUSCRIPTION_STATUS'; 
export const SET_SUSCRIPTIONS = 'SET_SUSCRIPTIONS'; 
export const SET_REAL_DATA_STRATEGY_CHART = 'SET_REAL_DATA_STRATEGY_CHART'; 
export const GET_REAL_DATA_STRATEGY_CHART = 'GET_REAL_DATA_STRATEGY_CHART'; 
export const SET_LOGIN_CHECK = 'SET_LOGIN_CHECK'; 
export const SET_DELAY_TIME = 'SET_DELAY_TIME'; 
export const GET_DELAY_TIME = 'GET_DELAY_TIME'; 
export const SET_REALTIME_DATA_OC_TIME = 'SET_REALTIME_DATA_OC_TIME'; 
export const SET_SCREEN = 'SET_SCREEN'; 
export const GET_SCREEN = 'GET_SCREEN'; 
export const SET_REAL_DATA_TICK_CHART = 'SET_REAL_DATA_TICK_CHART'; 
export const REMOVE_REAL_DATA_TICK_CHART = 'REMOVE_REAL_DATA_TICK_CHART'; 

export const SAVE_SRC = 'SAVE_SRC'; 
