import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Row, Col } from "react-bootstrap";
import {
  EmailValidation,
  PasswordValidation,
} from "../../util/input-validation.js";
import { loginUser } from "../../api/authentication";
import { saveUserCredentials } from "../../redux/actions/user";
 
import {deleteCookieCredentials , getLocalStorageObject, removeKeyLocalStorageObject} from '../../util/helper';


const axios = require('axios').default;

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initial = {
    email: "",
    password: "",
    remember: false,
    gtoken :""
  };
  const [formData, setFormData] = useState(initial);
  const [errorText, setErrorText] = useState(initial);
  const [showErrorText, setShowErrorText] = useState(false);
  const [requestError, setRequestError] = useState();
 

  useEffect(() => {
    let emailStatus = EmailValidation(formData.email);
    let passwordStatus = PasswordValidation(formData.password);

    let error = {
      email: emailStatus ? "" : "Enter a valid Email address",
      password: passwordStatus ? "" : "Enter a valid Password",
    };

    setErrorText(error);
    setShowErrorText(false);
    setRequestError("");
  }, [formData]);

  // useEffect(() => {
  //   if (packageReducer.suscriptionOrderId) {
  //     if (readyForPayment) {
  //       setReadyForPayment(false);
  //       history.push("/payment",{selectedPlan:selectedPlan,promoCode:promoCode});
  //     }
  //   }
  // }, [packageReducer.suscriptionOrderId]);

  const clearData = () => {
    setFormData(initial);
  };

  
  const setCookieCredentials = (loginSecret, userID, email, src) => {
    deleteCookieCredentials();
    
    document.cookie= "loginSecret=" + loginSecret;
    document.cookie= "userID=" + userID;
    document.cookie= "email=" + email;
    document.cookie= "src=" + src;

    
    
  }

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    // toast.success(`Logged in as ${formData.email}`);
    if (!errorText.email && !errorText.password) {
      const data = await loginUser(formData);

      if (data?.code === 200) {
        const {loginSecret, userID, email, userType, src} = data?.data;

        if(userType === "admin") {
          setCookieCredentials(loginSecret, userID, email, src);
          dispatch(saveUserCredentials({...data?.data, isLoggedIn: true}));
          navigate("/signup-user");
        } else {
          setRequestError("User not found");
        }
        
           
        
      } else if (data?.error) {
        setRequestError(data?.error);
      } else {
        setRequestError("Please check your internet connection and try again");
      }
    } else {
      setShowErrorText(true);
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
 



  return (
    <>
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
        {showErrorText && (
          <p
            className="text-danger"
            style={{ fontSize: "12px", paddingTop: "4px" }}
          >
            {errorText.email}
          </p>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
        {showErrorText && (
          <p
            className="text-danger"
            style={{ fontSize: "12px", paddingTop: "4px" }}
          >
            {errorText.password}
          </p>
        )}
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="d-none">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0 d-none">
              Remember Me
            </Form.Check.Label>
          </Form.Check>
        </Col>

       
      </Row>

      <Form.Group>
        {requestError && (
          <p style={{ color: 'red', fontSize: "12px",textTransform:"capitalize" }}>
              * {requestError}{' '}
          </p>
        )}
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
         Login
        </Button>
      </Form.Group>
   
 
     
    
    </Form>
   
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
