import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { freePlanNotAccessPage } from "../../../config";
import { useDispatch, useSelector } from "react-redux";

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const openCollapse = childrens => {
    const checkLink = children => {
      // if (children.to === pathname) {
      //   return true;
      // }
      return true;
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };
  const [open, setOpen] = useState(openCollapse(route.children));
  const [show, setShow] = useState(false);
  const [tickshow, setTickshow] = useState(false);
  const handleClosePopup =()=>{
    setShow(false);
    setTickshow(false)
  }

  return (
    <>
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          setOpen(!open);
          //document.getElementsByTagName('html')[0].classList.toggle('navbar-vertical-collapsed');
          //setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} setTickshow={(show)=>setTickshow(show)}setShow={(show)=>setShow(show)} pathname={pathname}/>
        </Nav>
      </Collapse>
    </Nav.Item>
  
  
     </>       
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes, setTickshow, setShow, pathname }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);
 

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const handleMenuClick = (e, routeName) => {
    
     
   
  }

  return routes.map(route => {
    if (!route.children) {
      return (
        <>
        <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
          <NavLink
            exact={route.exact}
            onClick={(e)=>handleMenuClick(e,route.to)} 
            to={
              route.to === '/authentication-modal'
                ? { pathname: '/authentication-modal', state: { open: true } }
                : route.to
            }
            isActive={match => {
              if (!match) {
                return false;
              }
              return route.to !== '#!';
            }}
            className={classNames('nav-link', { 'text-500': !route.active })}
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
         
       </>
      );
    }
    return <CollapseItems route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
