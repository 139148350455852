import React, { useEffect } from "react";
import {  Route, Routes, Navigate,useLocation } from "react-router-dom";
import is from "is_js";
import MainLayout from "./MainLayout";
//import SettingsPanel from "components/settings-panel/SettingsPanel";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NoInternetConnection from '../components/utilities/NoInternetConnection';
import Login from "../components/authentication/card/Login";
import {  useSelector } from "react-redux";
import Logout from "../components/authentication/card/Logout";


const Layout = () => {
  const history = useNavigate();
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);
   

  return (
    <>
     {(window.location.pathname !== "/authentication/login" && window.location.pathname !== "/authentication/logout") ?
      <MainLayout>
      <Routes>
          <Route path="/authentication/login" exact element={ <Login/> } />
          <Route path="/authentication/logout" exact element={<Logout />} />

      </Routes>
      </MainLayout>
    
    :<Routes>
        <Route path="/authentication/login" exact element={ <Login/> } />
        <Route path="/authentication/logout" exact element={<Logout />} />

    </Routes>
     }
    
      <ToastContainer autoClose={5000} closeOnClick />
      <NoInternetConnection></NoInternetConnection>

    </>
  );
};

export default Layout;
