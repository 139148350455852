import React, {useState, useEffect} from 'react';
import { ToastContainer, toast } from "react-toastify";

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    window.addEventListener('online', () => {
       // setOnline(true)
        toast('Your internet connection has been restored', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined, 
            theme: "colored",
        });
        return;
    },{once : true});

    window.addEventListener('offline', () => {
        // setOnline(false)
        toast('No internet Connection. Please refresh page!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined, 
            theme: "colored",
        });
            return;
    },{once : true});

    if(isOnline) {
        return(<></>);
    } else {
        return(<></>);
    }
}

export default NoInternetConnection;