 

export const version = "1.0.1 FNOTrader";
export const navbarBreakPoint = "xml"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: String(localStorage.getItem("isDark")) === "true" ? true : false,
  navbarPosition: "vertical",
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: "vibrant",
  supportEmail:"support@fnotrader.com",
  WhatsApp:"+91 88856 35467",
  Telegram:"https://t.me/fnotrader_com",
  Youtube:"https://www.youtube.com/channel/UCZawT_YUYCmK3fT9FLd6TPA",
  Blog:"https://www.blog.fnotrader.com/",
  phone:"+91 888 563 5467",
  twitter: "https://twitter.com/fnotrader_com"
};
 
export default settings;
