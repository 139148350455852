import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
  } from "react";
  
  import { useDispatch, useSelector, connect } from "react-redux";
  import { Link, useNavigate, useLocation } from "react-router-dom";
  import { Button,Tooltip, OverlayTrigger, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import classNames from 'classnames';
  import { signupReporListGet } from '../../../redux/actions/user';
  import moment from "moment";
  import {
    TableRow,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableSortLabel,
    Paper,
    Grid ,
    Typography 
  } from "@material-ui/core";
  import { Scrollbars } from 'react-custom-scrollbars-2';
  import { makeStyles } from "@material-ui/styles";
  import SearchDropDown from "../../../ui-components/SearchDropDown";
  import DropdownSelect from "../../../ui-components/DropdownSelect";
  import SearchCellString from "../../../ui-components/SearchCellString";
  import DatePicker from "react-datepicker";
  import {deleteCookieCredentials, filteringNumbersFunction, filteringStringFunction } from '../../../util/helper';

  const useStyles = makeStyles((theme) => ({
    heading: {
      color: ({ isDark }) => (isDark ? "#9da9bb" : "#5e6e82"),
      fontWeight: "bold",
      fontSize: "14px",
      padding: "2px 0px",
      fontFamily: "poppins",
      background: ({ isDark }) => (isDark ? "#0b1727" : "#edf2f9"),
    },
     
  
    headingCoBer: {
      color: ({ isDark }) => (isDark ? "#9da9bb" : "#fff"),
  
    },
    container: {
      width: "100%",
      height: "100%",
      overflowX: "hidden",
    },
    tblHeadCell: {
      backgroundColor: "#2196F3",
      fontSize: "11px",
      fontWeight: "800",
      textAlign: "center",
      color: "#fff",
      borderRight: "1px solid #fff"
    },
    summaryRow: {
      background: ({ isDark }) => (isDark ? "#0b1727" : "#edf2f9"),
    },
    tblHeader :{
      padding: "1px 2px !important",
      fontSize: "0.7rem",
   
       border: ({ isDark }) => (isDark ? "1px solid #121e2d" : "1px solid #ffffff"),
      fontWeight: "bold",
      fontFamily: "poppins",
      borderBottom: "none",
      textAlign: "left",
    },
    tableWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      flexDirection: "row",
      overflow: "hidden",
      width: "100%",
      overflowX: "hidden",
      backgroundColor: ({ isDark }) => {
        return isDark ? "#121e2d" : "#fff";
      },
    },
  }));

  
  const defcolumns = [
    { id: '1', key:"firstName", label: 'Name', minWidth: 200, isHide:false, BGcolor:"#edf2f9", isSearch:true, isString: true, align: "center",},
    { id: '2', key:"email", label: 'Email', minWidth: 170, isHide:false, BGcolor:"#edf2f9", isSearch:true, isString: true, align: "center",},
    { id: '3', key:"mobile", label: 'Mobile' , minWidth: 170, isHide:false, BGcolor:"#edf2f9" , isSearch:true, isString: true,align: "center", },
    { id: '4', key:"createdAt", label: 'Created At', minWidth: 170, isHide: false , BGcolor:"#edf2f9", isSearch:false, isString: false,align: "center",},
  ];

  const filter = ["Today", "This Week", "Last Week", "This Month", "Last Month", "This Quarter","Since Launch"]
  const UserList = ({type}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);
    const [filterValue, setfilterValue] = useState("");
    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loder, setLoder] = useState(true);
    const [filterEmpty, setFilterempty] = useState(false);
    const [list, setList] = useState([]);
    const filteringItemsRef = useRef({});
    const user = useSelector(state => state.user);

    useEffect(() => {
      if(type)  {
        setStartDate("");
        setEndDate("");
        setfilterValue(type);
      }
   

    }, [type]);


    useEffect(() => {
      async function fetch() {
  
        var start_date, end_date;
        if(startDate && endDate) {
          start_date = moment(startDate).format("YYYY-MM-DD");
          end_date = moment(endDate).format("YYYY-MM-DD");
        } else {
        
          if(filterValue === "Today") {
            start_date = moment().format("YYYY-MM-DD");
            end_date = moment().format("YYYY-MM-DD");
          } else if(filterValue === "This Week") {
            start_date =   moment().startOf('week').format("YYYY-MM-DD");
            end_date =  moment().endOf('week').format("YYYY-MM-DD");
          } else if(filterValue === "Last Week") {
            start_date =   moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD");
            end_date =  moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
          } else if(filterValue === "This Month") {
            start_date =   moment().startOf('month').format("YYYY-MM-DD");
            end_date =  moment().endOf('month').format("YYYY-MM-DD");
          }else if(filterValue === "Last Month") { 
            start_date =  moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
            end_date =  moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
          }else if(filterValue === "This Quarter") { 
            start_date =   moment().startOf('quarter').format("YYYY-MM-DD");
            end_date = moment().endOf('quarter').format("YYYY-MM-DD");
          }else if(filterValue === "Since Launch") { 
            start_date =  "2024-04-01";
            end_date = moment().format("YYYY-MM-DD");
          }

      }

        const Data = await signupReporListGet(
          user.src, 
          start_date,
          end_date,
          false
        ); 
        if (Data?.sessionExpired) {
          sessionExpireHandle();
          return
        }
        if(Data) {
          setUserList(Data?.users?.filter((item)=>item.email !== "gsakthidoss96@gmail.com") || []);
        }
        setLoder(false)
     }
    if(filterValue || (startDate && endDate)) {
      fetch();
    }
     
    }, [filterValue, startDate, endDate, user]);
  
  const onChangeFilter = (value) => {
    setfilterValue(value);
    setStartDate("");
    setEndDate("");
    filteringItemsRef.current = {};
  };
  
  const updateFilteredContentCallback = useCallback((val, id, isString) => {
    updateFilteredContent(val, id, isString, userList);
  }, [userList]);

  const updateFilteredContent = (val, id, isString, userList) => {
       filteringItemsRef.current[id] = { val, isString };
      let filteringArr = userList;
     Object.keys(filteringItemsRef.current).forEach((item) => {
      if (filteringItemsRef.current[item].isString) {
        filteringArr = filteringStringFunction(
          filteringArr,
          filteringItemsRef.current[item].val,
          item
        );
      } else {
        filteringArr = filteringNumbersFunction(
          filteringArr,
          filteringItemsRef.current[item].val,
          item
        );
      }
    });
    if(filteringArr.length == 0) {
      setFilterempty(true);
    } else {
      setFilterempty(false);
    }
    setList(filteringArr);
    return;
  };

  const sessionExpireHandle = () => {
    deleteCookieCredentials();
    navigate("/authentication/login");
  };
  
 
    return (
      // <Card  >
      //   <Card.Body>
          <Paper   className={`${classes.tableWrapper} opt-chn-tbl`}>
              <Grid
                container
                spacing={1}
                item
                justifyContent="start"
                alignItems="center"
                className="mb-2"
              >
              
                  <Grid item>
                    <Typography className={classes.textClass}>
                      Filter By
                    </Typography>
                    <DropdownSelect
                      options={filter}
                      width={150}
                      onChange={onChangeFilter}
                      size="middle"                  
                      value={filterValue}

                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textClass}>
                      Start Date
                    </Typography>
                    <DatePicker
                      selectsStart
                      selected={startDate}
                      onChange={(date) =>{ setStartDate(date); setfilterValue("");}}
                      startDate={startDate}
                      className="form-control form-control-sm pick-style"
                      dateFormat="yyyy-MM-dd"

                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textClass}>
                      End Date
                    </Typography>
                    <DatePicker
                      selectsEnd
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      endDate={endDate}
                      startDate={startDate}
                      minDate={startDate}
                      className="form-control form-control-sm pick-style"
                      dateFormat="yyyy-MM-dd"

                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textClass+" mt-3"}>
                      {userList.length} records found
                    </Typography>
                  </Grid>
            </Grid>
                
          {userList && userList.length > 0 &&  
            <TableContainer>

              <Scrollbars
                autoHeight
                autoHeightMax={"calc(110vh - 25.5rem)"}
                id="scroll-bar-id"
              >
                <Table
                    stickyHeader
                    size="small"
                    style={{
                      height: "100%",
                      padding: "0 12px",
                    }}
                  
                  >
                  <TableHead>
                    <TableRow >
                    {defcolumns.map((headCell) => (
                            <>
                            {!headCell.isHide &&
                          
                                <TableCell
                                  key={headCell.id}
                                  align={"left"}
                                  className={classes.tblHeader}
                                  id="fno-table-header-cell"
                                  style={{backgroundColor:headCell.BGcolor}}
                                >
                                
                                {headCell.label}
                              </TableCell>
                        
                          }
                            </>
                          ))}
                    </TableRow>
                    <TableRow className="filter">
                    {defcolumns.map((headCell) => (
                      <SearchCellString
                        key={headCell.key}
                        dkey={headCell.key}
                        name={headCell.label}
                        align={headCell.align}
                        isString={headCell.isString}
                        updateFilteredContentCallback={updateFilteredContentCallback}
                        isSearch={headCell.isSearch}
                      />
                    ))}
                  </TableRow>
                  </TableHead>
                  <TableBody  id="price-oi-table-id">
                    {list && list.length > 0 ? (
                      list.map((row, index) =>  {
                        return (
                        <TableRow role="checkbox" tabIndex={-1} key={'tblr_'+index}>
                              <TableCell   className={classes.headingCol+" dataCell text-800"}    align='left'>
                              <p style={{width:"185px"}} class="mb-0 ps-2 text-800"> {row.firstName} {row.lastName}</p>
                              </TableCell>
                              <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                                  <a href={`mailto:${row.email}`}class="mb-0">{row.email}</a>
                              </TableCell>
                              <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                                  <p >   {row.mobile} </p>
                              </TableCell>
                              <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                              <p style={{width:"105px"}}>  {moment(row.createdAt).format("DD-MMM-YYYY")} </p>
                              </TableCell>
                              


                        </TableRow>
                      )})
                    ) : !filterEmpty &&  userList && userList.length > 0 && userList
                      .map((row, index) => {
                        return (
                          <TableRow role="checkbox" tabIndex={-1} key={'tblr_'+index}>
                                <TableCell   className={classes.headingCol+" dataCell text-800"}    align='left'>
                                <p style={{width:"185px"}} class="mb-0 ps-2 text-800"> {row.firstName} {row.lastName}</p>
                                </TableCell>
                                <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                                    <a href={`mailto:${row.email}`}class="mb-0">{row.email}</a>
                                </TableCell>
                                <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                                    <p >   {row.mobile} </p>
                                </TableCell>
                                <TableCell   className={classes.headingCol+" dataCell"}    align='left'>
                                <p style={{width:"105px"}}>  {moment(row.createdAt).format("DD-MMM-YYYY")} </p>
                                </TableCell>
                                


                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Scrollbars>
            </TableContainer>
          }
          {loder && 
            <div style={{height:"100px",width:"100%"}} className="justify-content-md-center text-center d-flex  align-items-center">
                <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                  />
              </div>
          }
        
          </Paper>
    //     </Card.Body>
    //  </Card>
    );
  };
  
  export default UserList;
  