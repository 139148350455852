export const EmailValidation = (email) => {
    let isValid = false;
    if (typeof email !== 'undefined') {
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (pattern.test(email)) {
            isValid = true;
        }
    }
    return isValid;
};

export const PasswordValidation = (password) => {
    return TextValidation(password);
};

export const MobileValidation = (mobile) => {
    let isValid = false;
    if (typeof mobile !== 'undefined') {
        var pattern = /^\d{10,14}$/;
        
        if (pattern.test(mobile)) {
            isValid = true;
        }
    }
    return isValid;
};

export const TextValidation = (text) => {
    return !(typeof text === 'undefined' || text === '');
};

export const ConfirmPasswordValidation = (password, confirmPassword) => {
    return password === confirmPassword && TextValidation(password);
};
