//import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from 'redux';
import {thunk}  from 'redux-thunk';
import reducers from './reducers';

const Store = createStore(
    reducers,
    compose(applyMiddleware(thunk))
    //process.env.NODE_ENV === "development" ? composeWithDevTools(compose(applyMiddleware(thunk))) : compose(applyMiddleware(thunk))
);

export default Store;
  