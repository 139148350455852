import React from "react";
 
import { Select } from "antd";
import { makeStyles } from "@material-ui/styles";
import "./index.css";

const { Option } = Select;

// style constant
const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    fontFamily: 'poppins',
    fontSize: "14px",
    fontWeight: "bold",
  }
}));

const SearchDropDown = (props) => {
  const classes = useStyles();

  const { options, notFoundText, onSearch, onChange, defaultValue, size, placeholder, width} = props;
  return (
    <Select
      showSearch
      size={size}
      style={{ width: width }}
      defaultValue={ defaultValue }
      optionFilterProp="children"
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
      listHeight={256}
      notFoundContent={notFoundText}
      className={classes.dropdownItem}
    >
      {options &&
        options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
    </Select>
  );
};

export default SearchDropDown;
