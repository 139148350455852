import Store  from '../../src/redux';
import { sendRequest,sendRequestTarde } from './baseRequest';
import { getDomainName } from "../util/helper";

const getHeaders = () => {
    const user = Store?.getState()?.user;
    return {
        'X-FNOTRADER-SECRET': user?.loginSecret,
        'X-FNOTRADER-USERID': user?.userID
    };
}

 

export const loginUser = (data) => {
    const params = {
        email: data.email,
        password: data.password,
        gtoken:data.gtoken,
        src: getDomainName()
    };
    return sendRequest('post', 'user-login', params);
};

export const logoutUser = (data) => {
    const params = {
        loginSecret: data.loginSecret,
        email: data.email
    };
    return sendRequest('post', 'user-logout', params);
};


export const getprofile = () => {
   
    return sendRequestTarde('get', 'user-profile',  {}, getHeaders());
};