import React, { useContext,useState,useEffect  } from 'react';
import { Nav, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { Link} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
 import { makeStyles } from "@material-ui/styles";
import { freePlanNotAccessPage } from "../../../config";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import LLWfavicon from "../../../assets/icons/favicon.png";
import DropdownSelect from "../../../ui-components/DropdownSelect";
import { saveUserCredentials } from "../../../redux/actions/user";

const useStyles = makeStyles((theme) => ({
  paperItem: {
      padding: "0px",
      background: ({ isDark }) => (isDark ? "#0b1727" : "#fff"),
  },
  textClass: {
      margin: "1px 4px",
      padding: "1px 4px",
      fontSize: "11px",
      color: ({ isDark }) => (isDark ? "#9da9bb" : "#5e6e82"),
      fontWeight: "bold",
  },
  summaryFigure: {
      margin: "1px 4px",
      padding: "1px 4px",
      fontSize: "14px",
      fontWeight: "bold",
      color: ({ isDark }) => (isDark ? "#9da9bb" : "#5e6e82"),
      fontFamily: "poppins",
  },
}));  
const defoption = ['livelongwealth', 'fnotrader', 'All'];
const TopNavRightSideNavItem = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  
  const classes = useStyles({ isDark: isDark });
  const user = useSelector(state => state.user);

  const [src, setSrc] = useState('All'); 
  const [active, setActive] = useState(1); 
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

    async function save(src) {
      var val = {
         ...user,
         src:src === "All" ? '' : src
      };
      dispatch(saveUserCredentials(val));
    }
    if(user) {
      save(src);
    }
   
  },[src]);

  const handleChange = async (e) => {
     setSrc(e);
  }

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
      defaultActiveKey="/trading/dashboard"
      activeKey={active}
      onSelect={(selectedKey) => setActive(selectedKey)}
    >

      {user?.email === "gsakthidoss96@gmail.com" && 
        <Nav navbar className="me-2">
          <DropdownSelect
            options={defoption}
            width={150}
            size="middle"
            onChange={(e) => handleChange(e)}
            defaultValue={src}
            value={src}
          />
        </Nav>
      }
       <Nav.Item>
       <p className='m-0'>Admin  <img height={30} src={LLWfavicon} alt="fab-icon"/> <b>{user?.src}</b></p>
       </Nav.Item>
    
      {/* <Nav.Item className='d-none d-sm-block'>
        <Nav.Link
          as={'div'}
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id="hi">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
            placement="left"
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>  */}
  
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
