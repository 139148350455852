/* eslint-disable react/prop-types */
import React from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../components/pages/dashboard";
import UserList from "../components/pages/user-list";
import Payment from "../components/pages/payment";
import PaymentUsers from "../components/pages/payment-users";
import ProfileConfigration from "../components/pages/setting/ProfileConfigration";


 

const MainRoutes = () => (
  <Routes>
    <Route path="/"   element={ <PrivateRoute component={Dashboard}/> } />
    <Route path="/signup-user"   element={ <PrivateRoute component={Dashboard}/> } />
    <Route path="/user-list"   element={ <PrivateRoute component={UserList}/> } />
    <Route path="/subscription"   element={ <PrivateRoute component={Payment}/> } />
    <Route path="/subscription-users"   element={ <PrivateRoute component={PaymentUsers}/> } />
    <Route path="/profile"   element={ <PrivateRoute component={ProfileConfigration}/> } />

  </Routes>
);

export default MainRoutes;
