import React from "react";
import { Select } from "antd";
import { makeStyles } from "@material-ui/styles";
import "./index.css";

const { Option } = Select;

// style constant
const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    fontFamily: 'poppins',
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

const DropDownSelect = (props) => {
  const classes = useStyles();

  const { options, onChange, defaultValue, size, placeholder, width, value} = props;
  return (
    <Select
      size={size}
      style={{ width: width }}
      defaultValue={ defaultValue }
      listHeight={256}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={classes.dropdownItem}
      dropdownStyle={{
        zIndex:99999
      }}
    >
      {options &&
        options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
    </Select>
  );
};

export default DropDownSelect;
