import { SAVE_USER, REMOVE_USER,GET_USER_PROFILE } from '../../util/actionTypes';
import {getCredentialsFromCookie} from '../../util/helper';
const initialState = getCredentialsFromCookie();

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER:
            return action.payload;
        case GET_USER_PROFILE:
            return action.payload;
        case REMOVE_USER:
            return {};
        default:
            return state;
    }
};

export default userReducer;
