import { SAVE_USER, REMOVE_USER,GET_USER_PROFILE, SAVE_SRC } from "../../util/actionTypes.js";
import {signupReporList, loginReporList, masterReporList, paymentReporList} from "../../services/api.service.js";

export const saveUserCredentials = (data) => {
    return {
        type: SAVE_USER,
        payload: data
    };
};

export const saveSrc = (data) => {
    return {
        type: SAVE_SRC,
        payload: data
    };
};

export const removeUser = () => {
    return {
        type: REMOVE_USER,
        payload: {}
    };
}; 
export const getUserProfile = () => async (dispatch) => {
  return {
        type: GET_USER_PROFILE,
        payload: {}
    };
};
  
export const signupReporListGet = async (source,start_date,end_date,count) => {
    const res = await signupReporList(source,start_date,end_date,count);
    try {
    if (res.code === 200) {
        const data = res.data; 
        if (data) {
        return data;
        }
    } else if (res.code === 403) {
        return { sessionExpired : true };
    } else {
        console.log("error response code user/signup-report");
    }
    } catch (error) {
    console.log(
        "error processing user/signup-repor names request :",
        error
    );
    }
};

export const loginReporListGet = async (source,count) => {
    const res = await loginReporList(source,count);
    try {
    if (res.code === 200) {
        const data = res.data; 
        if (data) {
        return data;
        }
    } else if (res.code === 403) {
        return { sessionExpired : true };
    } else {
        console.log("error response code user/signup-report");
    }
    } catch (error) {
     console.log(
        "error processing user/signup-repor names request :",
        error
    );
    }
};

export const masterReporListGet = async (source,limit, offset, count) => {
    const res = await masterReporList(source,limit, offset, count);
    try {
    if (res.code === 200) {
        const data = res.data; 
        if (data) {
        return data;
        }
    } else if (res.code === 403) {
        return { sessionExpired : true };
    } else {
        console.log("error response code user/user-master-report");
    }
    } catch (error) {
    console.log(
        "error processing user/user-master-report names request :",
        error
    );
    }
};

export const paymentReporListGet = async (source,start_date, end_date, count) => {
    const res = await paymentReporList(source,start_date, end_date, count);
    try {
    if (res.code === 200) {
        const data = res.data; 
        if (data) {
        return data;
        }
    } else if (res.code === 403) {
        return { sessionExpired : true };
    } else {
        console.log("error response code user/payment-report");
    }
    } catch (error) {
    console.log(
        "error processing user/payment-report names request :",
        error
    );
    }
};