import { orderBy } from "natural-orderby";
import formatThousands from "format-thousands";
import {
   
  pagesRoutes,
 
} from "../routes/routes";
import moment from "moment";

export const getLocalStorageObject = (page) => {
  return JSON.parse(localStorage.getItem(page));
}
export const clearAllLocalStorageObject = (page) => {
  return localStorage.clear();
}
export const removeKeyLocalStorageObject = (page) => {
  return localStorage.removeItem(page);
}
export const setLocalStorageObject = (page, key, value, obj) => {
  obj[key] = value;
  localStorage.setItem(page, JSON.stringify(obj));
}

export const isEmptyObject = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const isFunction = (functionToCheck) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

export const availableMaxContainerHeight = () => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const menuBar = 70;
  const padding = 20;

  return vh - menuBar - padding;
};

export const sortArray = (
  ticks,
  data,
  staticData,
  sortProperty,
  isAcending
) => {
  try {
    const sortDirection = isAcending ? "asc" : "desc";
    const d = ticks.map((tick) => {
      return {
        ...data[tick],
        tick: tick,
        name: staticData[tick]?.name,
      };
    });
    const sortedData = orderBy(d, [(v) => v[sortProperty]], [sortDirection]);

    return sortedData.map((data) => data.tick);
  } catch (err) {
    console.log(err);
  }
};

export const sorEODArray = (
  data,
  sortProperty,
  isAcending
) => {
  try {
    const sortDirection = isAcending ? "asc" : "desc";
    const sortedData = orderBy(data, [(v) => v[sortProperty]], [sortDirection]);
    return sortedData;
  } catch (err) {
    console.log(err);
  }
};

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getCredentialsFromCookie = () => {
  let loginSecret = getCookie("loginSecret");
  let userID = getCookie("userID");
  let email = getCookie("email");
  let src = getCookie("src");


  let loggedIn = (loginSecret && userID && email) ? true : false;

  return { loginSecret, userID, email, src, isLoggedIn: loggedIn };
};

export const timeToLocal = (originalTime) => {
  const d = new Date(originalTime * 1000);
  return (
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds()
    ) / 1000
  );
};

export const convertUTCDateToLocalDate = (date) => {
  return new Date(date*1000).toISOString()
};

export const deleteCookieCredentials = () => {
  document.cookie = "loginSecret= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "userID= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "email= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "src= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"

};

export const  deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const getPageName = (url) => {
  let pageName = "";
 
  const pagesChildrens = pagesRoutes;
 

 
  const allChildrens = [
 
    ...pagesChildrens,
 
  ];

  allChildrens.forEach((child) => {
 
    var children  = child.children;
    if(children) {
      for (let i = 0; i < children.length; i++) {
        if (children[i].to === url) {
          pageName = children[i].name;
        }
      }
    } else {
      if (child.to === url) {
        pageName = child.name;
      }
    }  
    
    // if (child.to === '/pages/option-chain-charts') {
    //   defaultName = child.name;
    // }
  });
 
  document.title = `${pageName} - FNOTrader.com`;
  console.log("pageName", pageName)
  return pageName;
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function LightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}



export const fmt = (val) => {
  return formatThousands(val, ",");
};

export const toDecimalNumber = (val, number) => {
  return val?.toFixed(number);
};

export const formatNumberToText = (number, type) => {
  if (typeof number !== 'number') {
    return NaN; // return NaN if the argument is not a number
  }

  const numberLength = number.toString().length;
  var divided = 0;
  var text = "";
  if(numberLength <= 5) {
    divided = 1000;
    text = "K";
  } else if(numberLength > 5 && numberLength <= 7) {
    divided = 100000;
    text = "L";
  } else if(numberLength > 7) {
    divided = 10000000;
    text = "Cr";
  }
   
  return (number / divided).toFixed(2) + text;
}

export const getDomainName = () => {

  var domain = window.location.host.split('.');
  var domainName = "";
  if(domain[0] == "www") {
     
    domainName = domain[1];
  } else {
    domainName = domain[0];
  }
  return domainName;
};





export const filteringStringFunction = (filteringArr, val, id) => {
  return filteringArr.filter((value) => {
 
    let fieldValue = value[id]
    return fieldValue?.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  });
};

export const filteringNumbersFunction = (filteringArr, val, id) => {
 

  let operands = [">", "<", ">=", "<=", "="];
  let numbers = [];
  let operation = [];
  let charArr = val.trim().split("");
  for (var i = 0; i < charArr.length; i++) {
    if (operands.includes(charArr[i]) == false) {
      numbers.push(charArr[i]);
    } else {
      operation.push(charArr[i]);
    }
  }
  if (numbers && numbers.length > 0 && operation && operation.length > 0) {
    return checkNumberFiltering(
      filteringArr,
      parseFloat(numbers.join("")),
      operation.join(""),
      id
    );
  } else {
    return filteringArr;
  }
};

const checkNumberFiltering = (filteringArr, val, opr, id) => {
  return filteringArr.filter((token) => {
    let fieldValue = id === "packageEndTime"  ? moment(token[id]).diff(moment(), 'days') : token[id];
   
    if (fieldValue) {
      switch (opr) {
        case ">":
          return fieldValue > val;
        case "<":
          return fieldValue < val;
        case ">=":
          return fieldValue >= val;
        case "<=":
          return fieldValue <= val;
        case "=":
          return fieldValue == val;
      }
    }
  });
};