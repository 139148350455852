import axios from "axios"; 
const version_trader = "v1";
const version_stock = "v3";
const version_stock2 = "v2";
const version_trader2 = "v2";
const version_stock4 = "v4";
const version_stock5 = "v5";


export const sendRequestV1Post = async (type, url, params, headers) => {
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_STOCK}/${version_trader}/`,
    });
    
      let response = await clientAuth.post(`/${url}`, params);
      return response?.data;
    
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};

export const sendRequestV4 = async (type, url, params, headers) => {
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_LOGIN}/${version_stock4}/`,
    });
    const clientData = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_STOCK}/${version_stock4}/`,
      headers: headers
    });

    if (type === "post") {
      let response = await clientAuth.post(`/${url}`, params);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    } else if (type === "get") {
      let response = await clientData.get(`/${url}`, params);
      // let response = await axios.get(`${process.env.REACT_APP_API_URL_STOCK}/${version}/${url}`, params, headers);
      return response?.data;
    }
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};


export const sendRequest = async (type, url, params, headers) => {
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_LOGIN}/${version_trader}/`,
    });
    const clientData = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_STOCK}/${version_stock}/`,
      headers: headers
    });

    if (type === "post") {
      let response = await clientAuth.post(`/${url}`, params);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    } else if (type === "get") {
      let response = await clientData.get(`/${url}`, params);
      // let response = await axios.get(`${process.env.REACT_APP_API_URL_STOCK}/${version}/${url}`, params, headers);
      return response?.data;
    }
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};
export const sendRequestV2 = async (type, url, params, headers) => {
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_LOGIN}/${version_trader2}/`,
    });
    const clientData = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_STOCK}/${version_stock2}/`,
      headers: headers
    });

    if (type === "post") {
      let response = await clientAuth.post(`/${url}`, params);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    } else if (type === "get") {
      let response = await clientData.get(`/${url}`, params);
      // let response = await axios.get(`${process.env.REACT_APP_API_URL_STOCK}/${version}/${url}`, params, headers);
      return response?.data;
    }
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};
export const sendRequestV5 = async (type, url, params, headers) => {
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_LOGIN}/${version_trader2}/`,
    });
    const clientData = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_STOCK}/${version_stock5}/`,
      headers: headers
    });

    if (type === "post") {
      let response = await clientAuth.post(`/${url}`, params);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    } else if (type === "get") {
      let response = await clientData.get(`/${url}`, params);
      // let response = await axios.get(`${process.env.REACT_APP_API_URL_STOCK}/${version}/${url}`, params, headers);
      return response?.data;
    }
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};
export const sendRequestTarde = async (type, url, params, headers) => { 
  try {
    const clientAuth = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL_LOGIN}/${version_trader}/`,
      headers:headers 
    }); 
     
    if (type === "post") {
      let response = await clientAuth.post(`/${url}`, params,headers);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    }else if (type === "put") {
      let response = await clientAuth.put(`/${url}`, params,headers);
      // let response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN}/${version}/${url}`, params);
      return response?.data;
    } else if (type === "get") {
      let response = await clientAuth.get(`/${url}`, params,headers);
      // let response = await axios.get(`${process.env.REACT_APP_API_URL_STOCK}/${version}/${url}`, params, headers);
      return response?.data;
    }else if (type === "delete") {

      let response = await clientAuth.delete(`/${url}`,params,headers);
     
       //let response = await axios.delete(`${process.env.REACT_APP_API_URL_LOGIN}/${version_trader}/${url}`,headers);
      return response?.data;
    }
  } catch (e) {
    let error = e?.response?.data || "";
    console.log("error : ", e);
    return error;
  }
};
