import React, { useContext, useEffect,useState } from 'react';

import { Link } from 'react-router-dom';
import LoginForm from '../../../components/authentication/LoginForm';
import {isMobile} from 'react-device-detect';

import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Button, Form, Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router';
import logo from '../../../assets/img/favicons/android-chrome-512x512.png';
import AppContext from "../../../context/Context";

const Login = () => {

  const {
    setConfig,
  } = useContext(AppContext);

  const history = useNavigate();
   
  const navItems = [
    {
      icon: 'lock',
      label: 'Login'
    },
    {
      icon: 'user',
      label: 'Broker Login'
    } 
  ];
  const handleNavs = () => {
  }
 
  return (
    <AuthCardLayout
      leftSideContent={<></>}
    >
     <div>
          <Link
            className="text-white text-decoration-underline"
            to="/"
          >
            <div style={{marginBottom: "20px"}} className="d-flex justify-content-center align-items-center d-block d-sm-none">

            <img
              className="text-center"
              src={logo}
              alt=""
              width={40}
              style={{marginRight:"5px"}}
          />
            <h5 className="text-center " style={{color:"#2c87f2", fontWeight: "800", fontSize: "26px"}}>FNOTrader</h5>
           </div>
           </Link>
        </div>
      {/* <NavItemPill step={0}></NavItemPill> */}
    
         
      
      <div className={!isMobile ? "landing-page-header":""} style={{textAlign:"center",color:"#2c87f2", backgroundColor:  "#fff",paddingTop:"15px",paddingBottom:"15px"}}>
          <h4 style={{color:"#2c87f2"}}>Admin Login  </h4>
      </div>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
