import React, { useContext, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';
import { getPageName, isEmptyObject, getLocalStorageObject, setLocalStorageObject} from '../../../util/helper'
 
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

let localStorageObj = getLocalStorageObject('technical-analysis') || {
  chartView: {},
 
};
const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const location = useLocation();

   

  var  pageName = getPageName(location.pathname)
  
  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };


  return (
    <Navbar
      className="navbar-glass fs--1 navbar-top sticky-kit top-nav-wrap"
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={35} id="topLogo"/>
      {navbarPosition === 'top' || navbarPosition === 'combo' ? (
        <Navbar.Collapse
          in={navbarCollapsed}
          className="scrollbar pb-3 pb-lg-0"
        >
          <Nav navbar>
            <NavbarTopDropDownMenus />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <div
          className={classNames(
            'd-flex',
            'align-items-center',
          )}
        >
          <span className={"page-title"}>{pageName}</span>

        </div>
 
      )}

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
