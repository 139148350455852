import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";

import { useDispatch, useSelector, connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button,Tooltip, OverlayTrigger, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { paymentReporListGet } from '../../../redux/actions/user';
import moment from "moment";
import { type } from "@testing-library/user-event/dist/type";
import { object } from "is_js";
import Cardimg1 from "../../../assets/img/illustrations/corner-1.png";
import Cardimg2 from "../../../assets/img/illustrations/corner-4.png";
import Cardimg3 from "../../../assets/img/illustrations/corner-5.png";
import Background from '../../common/Background';
import PaymentUsers from '../payment-users';
import {isMobile} from 'react-device-detect';
import {deleteCookieCredentials } from '../../../util/helper';



const cardData = [
  {
    title: 'Today',
    valueClassName: 'text-warning',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg1,
    type:"today",
    value:"Todaydata"
  },
  {
    title: 'This Week',
    valueClassName: 'text-info',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg2,
    type:"week",
    value:"Weekdata"
  },
  {
    title: 'Last Week',
    valueClassName: '',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg3,
    type:"lastweek",
    value:"LastWeekdata"
  },
  {
    title: 'This Month',
    valueClassName: 'text-warning',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg1,
    type:"month",
    value:"Monthdata"
  },
  {
    title: 'Last Month',
    valueClassName: 'text-info',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg2,
    type:"lastmonth",
    value:"LastMonthdata"
  },
  {
    title: 'This Quarter',
    valueClassName: '',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg3,
    type:"quarterMonth",
    value:"QuarterMonthdata"
  },
  {
    title: 'Since Launch',
    valueClassName: '',
    link: '/user-list',
    linkText: 'See all',
    image: Cardimg1,
    type:"sinceLaunch",
    value:"sinceLaunch"
  },
];
const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [count, setCount] = useState({});
  const [loder, setLoder] = useState(true);
  const [type, setType] = useState("Today");
  const user = useSelector(state => state.user);

  useEffect(() => {

    async function fetch() {
      const Todaydata = await paymentReporListGet(
        user.src, 
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        true
      ); 

      const Weekdata = await paymentReporListGet(
        user.src, 
        moment().startOf('week').format("YYYY-MM-DD"),
        moment().endOf('week').format("YYYY-MM-DD"),
        true
      ); 

      const LastWeekdata = await paymentReporListGet(
        user.src, 
        moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD"),
        moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD"),
        true
      ); 

      const Monthdata = await paymentReporListGet(
        user.src, 
        moment().startOf('month').format("YYYY-MM-DD"),
        moment().endOf('month').format("YYYY-MM-DD"),
        true
      ); 

      const LastMonthdata = await paymentReporListGet(
        user.src, 
        moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
        moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD"),
        true
      ); 

      const QuarterMonthdata = await paymentReporListGet(
        user.src, 
        moment().startOf('quarter').format("YYYY-MM-DD"),
        moment().endOf('quarter').format("YYYY-MM-DD"),
        true
      ); 

      const sinceLaunch = await paymentReporListGet(
        user.src, 
        "2024-04-01",
        moment().format("YYYY-MM-DD"),
        true
      ); 

      if (Todaydata?.sessionExpired) {
        sessionExpireHandle();
        return
      }
      setCount({
        Todaydata,
        Weekdata,
        LastWeekdata,
        Monthdata,
        LastMonthdata,
        QuarterMonthdata,
        sinceLaunch
      });
      setLoder(false);
   }
    console.log("user", user)
   fetch();
  }, [user]);

   
  const handleRedirection = (name) => {
    setType(name);
  //  navigate("/payment-users", { state: { type: name } });
  }

  const sessionExpireHandle = () => {
    deleteCookieCredentials();
    navigate("/authentication/login");
  };
  return (
    <Card className={isMobile ? "":  "chartPageWrap"}>
      <Card.Body className={isMobile ? "overflow-scroll":  ""}>

     {count && Object.keys(count).length > 0 ? 
     <>
         <Row className="g-3 mb-3 card-div">
          {cardData.map((stat, index) => (
            <div key={stat.title} >
                <Card className={'overflow-hidden'} >
                  <Background image={stat.image} style={{BackgroundSize:"100% 100%" }} className="bg-card" />
                  <Card.Body className="position-relative">
                    <h6>
                      {stat.title}
                    </h6>
                    <div
                      className={classNames(
                        stat.valueClassName,
                        'display-4 fs-4 mb-2 fw-normal font-sans-serif'
                      )}
                    >
                       {count?.[stat.value]?.count}
                    </div>
                    <a href={"javascript:void(0)"} onClick={() => handleRedirection(stat.title)} className="fw-semi-bold fs--1 text-nowrap">
                      {stat.linkText}
                      <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                      />
                    </a>
                  </Card.Body>
                </Card>
            </div>
          ))}
        </Row>
        </>
        :
        <div style={{height:"100px",width:"100%"}} className="justify-content-md-center text-center d-flex  align-items-center">
          <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
            />
        </div>
    }
    <PaymentUsers type={type}></PaymentUsers>       
   </Card.Body>
   </Card>
  );
};

export default Payment;
