import React, { useContext, useEffect,useState } from 'react';
import {isMobile} from 'react-device-detect';
import {  Form, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../../context/Context";

const ProfileConfigration = () => {

  const {
    setConfig,
  } = useContext(AppContext);

  const history = useNavigate();
  const user = useSelector(state => state.user);

 
  return (
    <>  
    {/* <Row>
      <Col sm={3}>
      </Col>
      <Col sm={6}>
       <Card> 
          <Card.Body className="bg-light">
           <div className="page-title">
            <h4>Profile</h4> 
           </div>
          <div className="broker-config-form">
             <Form>
              <Row className="justify-content-md-center">
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>FirstName</Form.Label>
                    <Form.Control type="text" placeholder="First Name" readOnly value={this.state.user.firstName}/>
                  </Form.Group>
                </Col>
                <Col sm={6}> 
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>LastName</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" readOnly value={this.state.user.lastName}/>
                  </Form.Group>
                 </Col>
                 <Col sm={6}> 
                  <Form.Group className="mb-3" controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control type="text" placeholder="Mobile" readOnly value={this.state.user.mobile}/>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" readOnly value={this.state.user.email}/> 
                  </Form.Group>
                </Col> 
              </Row>
              </Form>
              <Row className="justify-content-md-center">
                <Col sm={12}>
                      <div className="susrciption-box text-center">

                          <h5>Subscription Status : </h5>
                            
                      </div>
                    
                </Col>
              </Row>
            </div>
           </Card.Body>
                     </Card>
          </Col> 
      </Row> */}
    </>
  );
};

export default ProfileConfigration;
