import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
 
  return isLoggedIn ? <Component /> :  <Navigate to={{ pathname: "/authentication/login" }} />

};

export default PrivateRoute;
