import Store  from '../../src/redux'; 
import {sendRequestTarde,sendRequest,sendRequestV2} from "../api/baseRequest";  
const getHeaders = () => {
    const user = Store?.getState()?.user;
    return {
        'X-FNOTRADER-SECRET': user?.loginSecret,
        'X-FNOTRADER-USERID': user?.userID,
        'Content-Type' : 'text/plain',
        //'Cache-Control' : 'no-cache,no-store',
    };
}
export const  signupReporList = (source,start_date,end_date,count) => {
    return sendRequestTarde(
      'get',
      `user/signup-report?source=${source}&start_date=${start_date}&end_date=${end_date}&count=${count}`,
      {},
      getHeaders()
    );
};

export const  loginReporList = (source,count) => {
    return sendRequestTarde(
      'get',
      `user/login-report?source=${source}&count=${count}`,
      {},
      getHeaders()
    );
};

export const  masterReporList = (source,limit, offset, count) => {
    return sendRequestTarde(
      'get',
      `user/user-master-report?source=${source}&limit=${limit}&offset=${offset}&count=${count}`,
      {},
      getHeaders()
    );
};

export const  paymentReporList = (source,start_date,end_date,count) => {
    return sendRequestTarde(
      'get',
      `user/payment-report?source=${source}&start_date=${start_date}&end_date=${end_date}&count=${count}`,
      {},
      getHeaders()
    );
};