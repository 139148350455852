import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, Navigate  } from 'react-router-dom';
// import Dashboard from 'components/dashboards/default';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from '../components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import { useDispatch, useSelector } from "react-redux";
import MainRoutes from './MainRoutes';


const MainLayout = () => {
  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
 
 
  return (

    <div className={isFluid ? 'container-fluid cf-main' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      
     
        <div className="content">
          <NavbarTop />
          
          <MainRoutes />
        </div>
 

    </div>
  );
};

export default MainLayout;
