import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AppContext from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearAllLocalStorageObject } from '../../../util/helper';
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import { faCog } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({}));

const ProfileDropdown = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const classes = useStyles({ isDark: isDark });

  const [cacheCleared, setCacheCleared] = useState(false);

  const handleClick = () => {
    setConfig('showSettingPanel', true);
  };
  const handleClearCache = () => {
    clearAllLocalStorageObject();
    toast.success(`Cache cleared successfully`);
    setCacheCleared(true);
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 nav-link"
      >
        {/* <Avatar src={team3} /> */}
        <div className="settings-input-label">
          <FontAwesomeIcon icon={faCog} className="fs-1"/>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
   
     
           {/* <Dropdown.Item as={Link} to="/profile" >
           Profile 
          </Dropdown.Item>   */}
          
          <Dropdown.Item as={Link} to="/authentication/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
