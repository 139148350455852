
export const pagesRoutes = [
    {
    label: 'Signup Users',
    labelDisable: true,
    
    children: [
        {
        name: "Signup Users",
        icon: "chart-pie",
        to: "/signup-user",
        active: true,
        
      },
    ]
  },{
    label: 'Subscription',
    labelDisable: true,
    
    children: [
        {
        name: "Subscription",
        icon: "chart-pie",
        to: "/subscription",
        active: true,
        
      },
    ]
  }
];


export default pagesRoutes;
